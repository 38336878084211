import React, { Fragment, PropsWithChildren, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import Link from "next/link";

export interface DropDownMenuOption {
  label: React.ReactNode;
  href?: string;
}

export interface DropDownMenuProps extends PropsWithChildren {
  className?: string;
  menuClassName?: string;
  hover?: boolean;
  description?: string;
  options: DropDownMenuOption[];
}

export const DropDownMenuButton: React.FC<DropDownMenuProps> = ({
  children,
  className,
  menuClassName,
  hover,
  description,
  options,
}) => {
  const [hoveredButton, setHoveredButton] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState(false);
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={`focus:outline-none ${className}`}
        onClick={() => setHoveredButton(!hoveredButton)}
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
      >
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        show={hover ? hoveredButton || hoveredMenu : undefined}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute left-[-24px] origin-top-left pt-3 focus:outline-none"
          static={hover}
          onMouseEnter={() => setHoveredMenu(true)}
          onMouseLeave={() => setHoveredMenu(false)}
        >
          <div
            className={`rounded-md bg-white px-6 py-4 shadow-lg focus:outline-none ${menuClassName}`}
          >
            {description && (
              <p className="mb-[2px] text-dim-gray">{description}</p>
            )}
            {options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => {
                  if (option.href) {
                    return (
                      <Link
                        href={option.href}
                        target={option.href.match(/http/) ? "_blank" : "_self"}
                        className={`${
                          active ? "underline" : ""
                        } group flex w-full items-center border-b py-3 text-sm text-gray-900`}
                      >
                        {option.label}
                      </Link>
                    );
                  }
                  return <></>;
                }}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
