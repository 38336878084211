import React, { useMemo } from "react";
import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import { useTranslations } from "next-intl";

import { motion } from "framer-motion";

import Animenz_logo_dark from "../public/animenz_logo_dark.png";
import Animenz_logo_light from "../public/animenz_logo_light.png";
import { useRouter } from "next/router";
import { video_site, video_site_zh } from "../public/data";
import { DropDownMenuButton, DropDownMenuOption } from "./Menu";
import { HiChevronDown } from "react-icons/hi";
import { ImArrowUpRight2 } from "react-icons/im";
import { Popover } from "@headlessui/react";
import { useScrollY } from "../util/hooks";
import { shouldDisplayChineseLocale } from "../util/service";

const WebsiteLogo = ({ dark }: { dark?: boolean }) => {
  return (
    <Link href="/">
      <div className="relative aspect-[1773/273] h-auto w-[150px] cursor-pointer max-[285px]:w-[120px]">
        <Image
          className="object-cover"
          src={dark ? Animenz_logo_dark : Animenz_logo_light}
          alt="Animenz Logo"
          fill
          sizes="300px" // use 300px here to make sure the image is not blurry
        />
      </div>
    </Link>
  );
};

interface NavBarProps {
  dark?: boolean;
}
interface NavLinkProps {
  href: string;
  name: React.ReactNode;
  isDark: boolean;
  scrollPosition: number;
}

const NavLink: React.FC<NavLinkProps> = ({
  href,
  name,
  isDark,
  scrollPosition,
}) => {
  return (
    <li className="ml-10 text-sm">
      <Link
        className={`relative cursor-pointer uppercase hover:underline ${
          isDark && scrollPosition <= 0
            ? "hover:text-regular-navy"
            : "hover:text-primary-light"
        }`}
        href={href}
        target={href.match(/http/) ? "_blank" : "_self"}
      >
        {name}
      </Link>
    </li>
  );
};

const Navbar: React.FC<NavBarProps> = ({ dark }) => {
  const { locale } = useRouter();

  const scrollPosition = useScrollY();

  const t = useTranslations("Navbar");

  const storeOptions: DropDownMenuOption[] = useMemo(
    () => [
      {
        label: (
          <div className="flex flex-row items-center">
            {t("stores.digital.label")}
            {/* <ImArrowUpRight2 className="ml-0.5 h-3 w-3" /> */}
          </div>
        ),
        href: t("stores.digital.link"),
      },
      {
        label: (
          <div className="flex flex-row items-center">
            {t("stores.book.label")}
            {/* <ImArrowUpRight2 className="ml-0.5 h-3 w-3" /> */}
          </div>
        ),
        href: t("stores.book.link"),
      },
    ],
    [t]
  );

  const [showStore, setShowStore] = useState(false);

  return (
    <div
      className={
        scrollPosition > 0
          ? "fixed z-10 h-14 w-full bg-[#040A16]/90 duration-500 ease-in-out"
          : "fixed z-[100] h-20 w-full duration-500 ease-in-out"
      }
    >
      {/* Main Desktop Navbar */}
      <div className="mx-auto hidden h-full w-full max-w-[1350px] items-center justify-between px-16 md:flex">
        <WebsiteLogo dark={dark && scrollPosition <= 0} />
        <div className="p-5">
          <ul
            className={
              dark && scrollPosition <= 0
                ? "hidden justify-evenly text-regular-navy/80 md:flex"
                : "hidden justify-evenly text-primary-light/80 md:flex"
            }
          >
            <NavLink
              href="/about"
              name={t("links.about")}
              isDark={dark ?? false}
              scrollPosition={scrollPosition}
            />
            <NavLink
              href="/news"
              name={t("links.news")}
              isDark={dark ?? false}
              scrollPosition={scrollPosition}
            />
            <NavLink
              href="/sheets"
              name={t("links.sheets")}
              isDark={dark ?? false}
              scrollPosition={scrollPosition}
            />
            <li className="ml-10 text-sm">
              <DropDownMenuButton
                hover
                className={`
                  cursor-pointer uppercase hover:underline 
                  ${
                    dark && scrollPosition <= 0
                      ? "hover:text-regular-navy"
                      : "hover:text-primary-light"
                  }`}
                menuClassName="w-[290px]"
                description={t("stores.description")}
                options={storeOptions}
              >
                <div className="flex flex-row items-center">
                  {t("links.store")}
                  {/* <HiChevronDown className="absolute left-full h-4 w-4" /> */}
                </div>
              </DropDownMenuButton>
            </li>
            <NavLink
              href={
                shouldDisplayChineseLocale(locale) ? video_site_zh : video_site
              }
              name={t("links.video")}
              isDark={dark ?? false}
              scrollPosition={scrollPosition}
            />
            <NavLink
              href="/contact"
              name={t("links.contact")}
              isDark={dark ?? false}
              scrollPosition={scrollPosition}
            />
          </ul>
        </div>
      </div>
      {/* Mobile Navbar */}
      <Popover className="h-full">
        {({ open, close }) => (
          <>
            <div className="flex h-full w-full items-center justify-between px-10 md:hidden">
              <WebsiteLogo dark={dark && scrollPosition <= 0} />
              <Popover.Button
                className={`
                  focus:outline-none md:hidden
                  ${
                    dark && scrollPosition <= 0
                      ? "text-black"
                      : "text-primary-light"
                  }
                `}
              >
                <AiOutlineMenu size={25} />
              </Popover.Button>
            </div>
            <Popover.Overlay
              className={
                open
                  ? "fixed left-0 top-0 h-screen w-full bg-black/60 md:hidden"
                  : ""
              }
            />
            <Popover.Panel static>
              <motion.div
                initial={{ x: "-100%" }}
                animate={{ x: open ? "0" : "-100%" }}
                transition={{ duration: 0.3 }}
                className="m:w-[45%] fixed left-0 top-0 h-screen w-[75%] bg-[#040A16]/90 p-10 sm:w-[60%] md:hidden"
              >
                <div>
                  <div className="flex w-full items-center justify-between">
                    <div onClick={close}>
                      <WebsiteLogo />
                    </div>
                    <div
                      onClick={close}
                      className={
                        `cursor-pointer p-5 text-slate-200 ` +
                        (open ? "" : "hidden")
                      }
                    >
                      <AiOutlineClose />
                    </div>
                  </div>
                  <div className="my-4 border-b border-gray-300/40 text-primary-light/70">
                    <p className="py-4">&nbsp;</p>
                  </div>
                </div>
                <motion.div className="flex flex-col py-4 text-primary-light">
                  <ul className="uppercase">
                    <Link href="/about">
                      <li onClick={close} className="py-4 text-sm">
                        {t("links.about")}
                      </li>
                    </Link>

                    <Link href="/news">
                      <li onClick={close} className="py-4 text-sm">
                        {t("links.news")}
                      </li>
                    </Link>
                    <Link href="/sheets">
                      <li onClick={close} className="py-4 text-sm">
                        {t("links.sheets")}
                      </li>
                    </Link>

                    <li
                      className="py-4 text-sm"
                      onClick={() => {
                        setShowStore(!showStore);
                      }}
                    >
                      <div className="flex flex-row items-center">
                        {t("links.store")}
                        <motion.div
                          variants={{
                            open: { rotate: 180 },
                            closed: { rotate: 0 },
                          }}
                          animate={showStore ? "open" : "closed"}
                          transition={{ duration: 0.2 }}
                        >
                          {/* <HiChevronDown className="h-4 w-4" /> */}
                        </motion.div>
                      </div>
                    </li>
                    <motion.ul
                      className="ml-8 overflow-hidden"
                      animate={showStore ? "open" : "closed"}
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        closed: { opacity: 0, height: 0 },
                      }}
                    >
                      <li className="mx-2 py-4 text-sm">
                        <Link
                          href={t("stores.digital.link")}
                          target="_blank"
                          className="inline-block"
                        >
                          {t("stores.digital.label")}
                          {/* <ImArrowUpRight2 className="ml-0.5 inline-block h-3 w-3" /> */}
                        </Link>
                      </li>
                      <li className="mx-2 py-4 text-sm">
                        <Link
                          href={t("stores.book.link")}
                          target="_blank"
                          className="inline-block"
                        >
                          {t("stores.book.label")}
                          {/* <ImArrowUpRight2 className="ml-0.5 inline-block h-3 w-3" /> */}
                        </Link>
                      </li>
                    </motion.ul>
                    <Link
                      target="_blank"
                      href={
                        shouldDisplayChineseLocale(locale)
                          ? video_site_zh
                          : video_site
                      }
                    >
                      <li onClick={close} className="py-4 text-sm">
                        <div className="flex flex-row items-center">
                          {t("links.video")}
                          {/* <ImArrowUpRight2 className="ml-0.5 h-3 w-3 pb-[1px]" /> */}
                        </div>
                      </li>
                    </Link>
                    <Link href="/contact">
                      <li onClick={close} className="py-4 text-sm">
                        {t("links.contact")}
                      </li>
                    </Link>
                  </ul>
                </motion.div>
              </motion.div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      {/* Mobile Navbar side drop */}
    </div>
  );
};

export default Navbar;
